import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  finalize,
  map,
  shareReplay,
  tap,
} from 'rxjs/operators';

import {
  FlashyCustomDataService,
  FlashyStoreService,
  IFlashyPagination,
} from '@flashy/store';

export interface IFlashyUiImageFile {
  id: number;
  title: string;
  path: string;
  thumbnail: string;
}

export interface IFlashyUiProduct extends IFlashyUiImageFile {
  type: string;
  catalog_id: number;
  condition: string;
  product_type: string;
  availability: string;
  image_link: string;
  description: string;
  link: string;
  sale_price: number;
  price: number;
}

const cacheProducts = new Map<string, Observable<IFlashyUiProduct[]>>();

@Injectable({
  providedIn: 'root',
})
@Injectable({
  providedIn: 'root',
})
export class FlashyProductsService extends FlashyCustomDataService<IFlashyUiProduct> {
  uniqueKey: keyof IFlashyUiProduct = 'id';
  isLoading$ = this.storeService.isLoading$;

  constructor(private storeService: FlashyStoreService) {
    super();
  }

  /**
   * Get products list, cache the request for 700ms
   *
   * @param params
   */
  getProductsList(params: {
    ids?: string[];
    query?: string;
    page: number;
    catalog?: number;
    stock?: string;
    variant?: number;
  }): Observable<IFlashyUiProduct[]> {
    if (params.ids?.length) {
      return this.getProducts(params);
    }
    if (!cacheProducts.has(params.query)) {
      cacheProducts.set(params.query, this.getProducts(params));
    }
    return cacheProducts.get(params.query);
  }

  getProducts(params: {
    ids?: string[];
    query?: string;
    page: number;
    catalog?: number;
    stock?: string;
    variant?: number;
  }): Observable<IFlashyUiProduct[]> {
    return this.storeService
      .makeHttpRequest<
        IFlashyPagination<IFlashyUiProduct>
      >('POST', 'items/products', params, { silent: true })
      .pipe(
        map((res) => res?.results),
        shareReplay(),
        debounceTime(700), // debounce for 700ms
        finalize(() => cacheProducts.delete(params.query)) // remove from cache
      );
  }

  // getProductsList(params: {
  //   ids?: string[];
  //   query?: string;
  //   page: number;
  //   catalog?: number;
  //   stock?: string;
  //   variant?: number;
  // }): Observable<IFlashyUiProduct[]> {
  //   if (!cacheProducts.has(params.query)) {
  //     cacheProducts.set(params.query, this.storeService.makeHttpRequest<{
  //       results: IFlashyUiProduct[];
  //     }>('POST', 'items/products', params, {silent: true}).pipe(
  //       map(res => res?.results),
  //       shareReplay(),
  //       debounceTime(700), // debounce for 700ms
  //       finalize(() => cacheProducts.delete(params.query)), // remove from cache
  //     ));
  //   }
  //   return cacheProducts.get(params.query);
  // }

  getAll(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params?: Record<any, any>
  ): Observable<IFlashyPagination<IFlashyUiProduct[]>> {
    return this.storeService
      .makeHttpRequest<
        IFlashyPagination<IFlashyUiProduct>
      >('POST', 'items/products', params, { silent: true })
      .pipe(
        tap(({ results, total }) => {
          this.setCount(total);
          if (!results.length) {
            this.setEntities([]);
          } else {
            this.addEntities(results);
          }
        }),
        catchError((error) => {
          this.setEntities([]);
          this.setCount(0);
          return of(error);
        })
      );
  }
}
